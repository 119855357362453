.navbar-section {
    font-family: Tajawal;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    height: 100px;
    padding: 0;
}

.navbar-button.guide {
    background-color: #F0ECE5;
}

.navbar-button.questions {
    background-color: #F5F4F0;
}

.navbar-inner {
    height: 100%;
    align-items: stretch;
}

.navbar-logo {
    margin: 0 0 0 1.5rem;
    display: flex;
}

.navbar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2.5rem !important;
    color: #1F2732;
}

.navbar-login-btn {
    display: flex;
    width: 263px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border: #E3E1DC 1px solid!important;
    border-radius: 4px;
    color: #1F2732!important;
    gap: 10px;
    line-height: 21.6px;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: 24px;
    font-family: 'Tajawal'!important;
    padding: 0;
}

.navbar-login-btn.no-border{
    border: 0!important;
}

.navbar-container {
    height: 100%;
    max-width: unset!important;
    padding: 0;
}

.navbar-container-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    background: white;
    padding: 0 1.5rem;
}

.navbar-collapse {
    justify-content: space-between;
    height: 100%;
    transition: none;
}

.navbar-nav {
    height: 100%;
}

.navbar-toggler-icon {
    background-image: url('../../public/navbar-close.svg');
    width: 14px;
    height: 14px;
}

.navbar-toggler.collapsed > .navbar-toggler-icon {
    background-image: url('../../public/navbar-burger.svg');
    width: 18px;
    height: 16px;
}

.navbar-toggler {
    border: none;
    padding: 0;
    box-shadow: none !important;
}

@media (max-width: 768px) {
    .navbar-section.popup{
        display: none;
    }
}

@media (max-width: 990px) {

    .navbar-container {
        padding: 0;
        max-width: unset;
    }

    .navbar-logo-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 20px;
    }

    .navbar-container-inner {
        flex-wrap: wrap;
        padding: 2rem 0;
    }

    .navbar-button {
        background-color: #fff !important;
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        justify-content: space-between;
        padding: 1rem 0.5rem !important;
        color: #34312A;
    }

    .navbar-collapse {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        gap: 2rem;
        margin-top: 20px;
        padding-bottom: 30px;
        overflow: auto;

    }

    .navbar-nav {
        height: fit-content;
        width: 100%;
        padding: 0 20px;
    }

    .navbar-login-btn-wrapper {
        background: linear-gradient(180deg, #E6E1D9 0%, #E4DDCD 100%);
        width: 100%;
        height: 125px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .navbar-login-btn {
        width: 298px;
        height: 68px;
        background: #FFFFFF!important;
    }

    .navbar-section {
        
        position: relative;
        z-index: 10;
    }

    .navbar-section.expanded{
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 1000;
        top: 0;
    }

}