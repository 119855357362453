@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');


html{
    direction: rtl;
}

body{
    font-family: Tajawal!important;
    /* background-color: #F1F1F1!important; */
}

@media (min-width: 1600px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1600px!important;
    }
}
