.footer-section.mobile {
    display: none;
}

.footer-container {
    display: flex;
    height: 60px;
    max-width: unset !important;
    padding: 0 !important;
}

.footer-section {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 0 3.5rem;
    background-color: #E4DDCD;
}

.footer-section {
    font-family: Tajawal;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: right;

}

.footer-bold {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.footer-section.left {
    justify-content: space-between;
    background-color: #B4975ACC;
    padding: 0 2.5rem 0 3.5rem;
}

.footer-section-social {
    display: flex;
    gap: 15px;
}

.footer-section-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
}

.footer-credits {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

@media (max-width: 1200px) {
    .footer-section {
        font-size: 12px;
    }

    .footer-social-logo {
        width: 20px;
    }

    .footer-bold {
        font-size: 14px;
    }
}

@media (max-width: 990px) {
    .footer-section {
        font-size: 10px;
        padding: 0 0.5rem;

    }

    .footer-social-logo {
        width: 15px;
    }

    .footer-bold {
        font-size: 12px;
    }

    .footer-container {
        display: grid;
        /* justify-content: center; */
        justify-items: center;
        width: 100%;
        padding: 0;
        height: fit-content;
        margin-top: 63px;
    }

    .footer-section {
        width: 100%;
    }

    .footer-section.left {
        height: 67px;
        justify-content: center;
    }

    .footer-section.right {
        display: none;
    }

    .footer-credits {
        display: none;
    }

    .footer-section-wrapper{
        margin-right: 20px;
    }

    .footer-section.mobile {
        display: grid;
        justify-content: center;
        background: #1F2732;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #FFFFFF;
        text-align: center;
        padding: 35px 35px 14px 35px;
        gap: 2rem;
    }

    .footer-section.mobile.global {
        background-color: #FFFFFF;
        color: #000;
        justify-content: end;
        height: 52px;
        padding: 13px 0 15px 11px;
    }

    .footer-section.mobile.global>.footer-credits-mobile {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        opacity: 100%;
        margin: 0;

    }


    .footer-social-logo {
        width: 30px;
    }

    .footer-bold {
        font-size: 16px;
    }

    .footer-credits-mobile {
        font-size: 15px;
        font-weight: 400;
        margin-top: 1.5rem;
        opacity: 60%;
    }
}

@media (min-width: 576px) {

    .container,
    .container-sm {
        max-width: unset;
    }
}