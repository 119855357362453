.home-section {
    height: 860px;
    display: flex;
    justify-items: center;
    justify-content: center;
    min-height: calc(100vh - 160px);
}

.home-section-inner {
    width: 50%;
    padding: 14rem 3rem 0 0;
}

.home-section-inner.right {
    background: linear-gradient(180deg, #E6E1D9 0%, #E4DDCD 100%);
    position: relative;
}

.home-section-inner.left {
    background: #B4975A;
    position: relative;
}

.home-section-back.right {
    width: fit-content;
    position: absolute;
    right: 0;
    bottom: 0;
}

.home-section-back.left {
    width: fit-content;
    position: absolute;
    right: 0;
    top: 0;
    mix-blend-mode: color-dodge;
}

.home-section-title {
    font-size: 70px;
    font-weight: 500;
    line-height: 84px;
    color: #1F2732;
}

.home-section-title.left {
    color: #EAE6E0;
}

.home-section-text {
    font-size: 19px;
    font-weight: 400;
    line-height: 24px;
}

.home-section-text.left {
    color: #fff;
}

.home-section-button {
    width: 323px;
    height: 48px;
    border-radius: 40px;
    background-color: #825925;
    border: none;
    color: #fff;
    font-family: 'Tajawal';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    justify-content: end;
    gap: 70px;
    padding-left: 50px;
}

.home-section-button:hover {
    background-color: #825925;
}

.home-section-button:active {
    background-color: #825925 !important;
}

.home-section-button.left {
    color: #825925 !important;
    background-color: #fff !important;
}

.home-section-button.left.bottom {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 72px;
    padding: 0;
    color: #F5F4F0 !important;
    background-color: transparent !important;
    justify-content: center;
    gap: 10px;
    width: fit-content;
    height: fit-content;
    text-decoration: underline;
}

.home-section-content {
    position: relative;
    z-index: 1;
    
}

.home-section-title.left strong {
    position: relative;

}

.home-section-title-img {
    position: absolute;
    top: 110px;
    right: 350px;
    
}

.home-container {
    max-width: unset!important;
    padding: 0;
}

@media (min-width: 991px) and (max-width: 1286px)  {
    .home-section-title {
        font-size: 56px;
        line-height: 64px;
    }
    .home-section-title-img {
        width: 175px;
        right: 270px;
        top: 80px;
    }
}

@media (max-width: 990px) {

    .home-section {
        flex-wrap: wrap;
        height: fit-content;
        min-height: calc(100vh - 556px);
    }

    .home-section-inner {
        width: 100%;
        padding: 3rem 3rem 0 0;
        overflow: hidden;
    }


    .home-section-back.right {
        height: 250px;
    }

    .home-section-content {
        margin: 3rem 0;
        
    }

    .home-section-title-img {
        width: 200px;
        right: 360px;
        top: 100px;
        
    }

    .home-section-button.left.bottom {
        margin-top: 45px;
    }


}

@media (max-width: 600px) {

    .home-section-title {
        font-size: 40px;
        font-weight: 500;
        line-height: 48px;
    }

    .home-section-text {
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
    }

    .home-section-button {
        width: 208px;
        height: 48px;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        gap: 35px;
        padding-left: 25px;
    }

    .home-section-inner {
        padding: 2rem 1rem 0 0;
    }

    .home-section-back.right {
        height: 200px;
    }

    .home-section-title-img {
        width: 112px;
        right: 210px;
        top: 50px;
    }
}

@media (max-width: 786px) {

}

@media (max-width: 350px) {
    .home-section-title {
        font-size: 32px;
    }

    .home-section-title-img {
        width: 100px;
        right: 160px;
        top: 50px;
    }
}

